.container {
  background: transparent;
  border-bottom: 1px solid white;
  padding-inline: 0;
  .logo {
    background: url("../../assets/svg/logo.svg");
  }

}
@media screen and (max-width: 1200px) {
  .container {
    padding-inline: 10px;
  }
}
.drawerBodyStyle {
  &.drawerBodyStyle {
    background: url("../../assets/images/main-bg.jpg");;
  }
}
.navTitles {
  cursor: pointer;
  text-align: center;
  border-bottom: 1px solid #fff;
  padding-block: 10px;
}
.hamburgerIcon {
  color: #fff;
  font-size: 24px;
}
