.container {
  margin-top: 40px;
  border-top: 1px solid white;
  padding-inline: 0;
}
@media screen and (max-width: 1200px) {
  .container {
    padding-inline: 10px;
  }
}
