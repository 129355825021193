.edit-btn {
  padding: 3px 15px;
  border-radius: 4px;
  background: linear-gradient(180deg, #8A01D7 0%, #0381A6 100%) !important;
  color: white;
  font-size: 16px;
  line-height: 1.5;
  display: flex;
  justify-content: center;
  cursor: pointer;
  margin-bottom: 8px;
  margin-top: 20px;
  border: 1px solid transparent;
  box-shadow: 0 2px 0 rgba(255, 255, 255, 0.04);
  transition: all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);

  &:hover {
    color: #cfbae8;
    border-color: #cfbae8;
  }
}

.pen-icon {
  background-color: white;
  padding: 8px;
  border-radius: 50%;
  box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.16);
  transition: background-color 0.2s;

  svg {
    fill: black;
    transition: fill 0.2s;
  }

  &:hover {
    svg {
      fill: #6c6c6c;
      transition: fill 0.2s;
    }
  }
}