.container {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.countryOptionRow {
  display: flex;
  align-items: center;
  gap: 5px;
  .flagIcon {
    width: 18px;
  }
}
