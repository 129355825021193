.formContainerWrapper {
  display: flex;
  justify-content: center;
  width: 100%;
}
.formContainer {
  padding: 24px;
  width: 100%;
  border-radius: 20px;
  border: 1px solid #fff;
  max-width: 500px;
}

