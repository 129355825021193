.container {
  position: relative;
  width: 100%;
}
.input {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
}
.digit {
  width: 67px;
  height: 64px;
  border-radius: 8px;
  font-size: 38px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #E5E5E5;
  cursor: text;
}

@media screen and (max-width: 768px) {
  .digit {
    width: 40px;
    height: 40px;
    font-size: 26px;
  }
}
@media screen and (max-width: 576px) {
  .digit {
    width: 35px;
    height: 35px;
    font-size: 20px;
  }
}
@media screen and (max-width: 480px) {
  .digit {
    width: 28px;
    height: 28px;
    font-size: 12px;
  }
}
