@import "reset.css";

body {
    background-size: cover;
    background-repeat: no-repeat;
    background-color: #180429;
    background-image: url('../assets/images/main-bg.jpg');
    min-height: 100vh;
    color: white;
    font-size: 16px;
    scroll-behavior: smooth;
}
.app-wrapper {
    display: flex;
    justify-content: center;
}
.app-content {
    max-width: 1400px;
}

.w100 {
    width: 100%;
}
.color-white {
    color: white;
}
.text-center {
    text-align: center;
}

.ant-form-item-explain-error, .ant-form-item-explain-success {
    font-size: 12px;
    margin-bottom: 10px;
}

.ant-btn-primary, .ant-btn-default {
    background: linear-gradient(180deg, #8A01D7 0%, #0381A6 100%) !important;
    color: white;
    min-width: 160px;
}
.ant-table-filter-dropdown-btns .ant-btn-primary {
    min-width: 70px !important;
}
.no-margin-bottom {
    margin-bottom: 0;
}

.d-flex {
    display: flex;
}
.row {
    flex-direction: row;
}
.column {
    flex-direction: column;
}
.a-center {
    align-items: center;
}
.j-center {
    justify-content: center;
}
