.sideBarContainer {
  background-color: rgba(255, 255, 255, 0.1);
  width: 100%;
  padding: 24px;
  border-radius: 8px;
  .menuBtn {
    display: flex;
    align-items: center;
    svg {
      margin-right: 5px;
    }
  }
}
.plusIcon {
  margin-left: 5px;
}

@media screen and (max-width: 768px) {
  .sideBarContainer {
    padding: 18px 15px;
  }
}
