.completedTransaction {
  background-color: rgba(34, 255, 0, 0.15);
}
.errorTransaction {
  background-color: rgba(255, 0, 0, 0.15);
}
.amountSecondaryText {
  font-size: 9px;
  color: #cccccc;
  white-space: nowrap;
}
